/* eslint-disable max-lines */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tag } from '@getro/rombo';
import moment from 'moment';
import { Card, Flex, Box, Text, Button, Link } from 'rebass/styled-components';
import { Heart, X, ChevronRight, Calendar, Banknote, MapPin } from 'lucide-react';
import NextLink from 'next/link';
import { DeletedOverlay } from '../deletedOverlay';
import usePreviousPageQueryParams from '../../../hooks/usePreviousPageQueryParams';
import { ButtonToggle } from '../../atoms/buttonToggle';
import { companyStageFormatter, mapHeadCountToInterval } from '../../../helpers/stringHelper';
import { addUTMSource } from '../../../helpers/queryString';
import { NetworkSchema } from '../../../schema/network';
import useTagsVisibility from '../../../hooks/useTagsVisibility';
import { ProfilePicture } from '../../atoms/profilePicture';
import { moveRemoteToEnd } from '../../../helpers/array';
import { hasAdvancedDesignSelector } from '../../../redux/selectors/network';
import { getAPCAColor } from '../../../helpers/color';
import { JOB_SENIORITY_VALUES } from '../../../constants/index';
import { DisplayCompensationPeriod } from '../../atoms/displayCompensationPeriod';

export const JobListItem = ({
  id,
  isLast,
  createdAt,
  slug,
  source,
  locations,
  organization,
  title,
  description,
  seniority,
  canFavorite,
  canDiscard,
  isFavorite,
  isDiscarded,
  isFeatured,
  onFavorite,
  showDiscardedOverlay,
  url,
  onDiscard,
  onJobLinkClick,
  simplifiedVersion,
  hasDescription,
  network,
  compensation,
  ...rebassProps
}) => {
  const query = usePreviousPageQueryParams();
  const isExtractedJobWithDescription = source === 'career_page' && hasDescription;
  const { name: networkName } = network;
  const { hasIndustryTagsVisible, hasCompanySizeTagsVisible, hasStageTagsVisible, visibleTopics } = useTagsVisibility({
    network,
    company: organization,
  });

  const hasAdvancedDesign = hasAdvancedDesignSelector(network);
  const { primary } = getAPCAColor(hasAdvancedDesign);

  const jobUrl =
    source === 'admin_portal' || isExtractedJobWithDescription
      ? `${process.env.NEXT_PUBLIC_BASE_PATH}/companies/${organization.slug}/jobs/${slug}#content`
      : addUTMSource(url, networkName);

  const onJobLinkClickHandler = () => {
    onJobLinkClick({
      source,
      organizationId: organization.id,
      title,
      id,
      featured: isFeatured,
    });
  };

  const hasTags = useMemo(
    () => visibleTopics.length > 0 || hasIndustryTagsVisible || hasCompanySizeTagsVisible || hasStageTagsVisible,
    [visibleTopics.length, hasIndustryTagsVisible, hasCompanySizeTagsVisible, hasStageTagsVisible],
  );

  return (
    <Card
      tx="card"
      variant="listItem"
      sx={{
        boxShadow: 'xs',
        borderRadius: '4px',
        borderLeftColor: isFeatured ? 'customPrimary' : 'unset',
        borderLeftWidth: isFeatured ? '4px' : 'unset',
        borderLeftStyle: isFeatured ? 'solid' : 'unset',
        borderBottomWidth: isLast ? '1px' : '0',
        ':hover': {
          '.hover-active': {
            opacity: 0.5,
          },
        },
        '&:not(:last-child)': {
          marginBottom: [2],
        },
      }}
      p={[2, 3]}
      className={`job-card ${showDiscardedOverlay && isDiscarded ? 'discarded' : ''}`}
      data-testid="job-list-item"
      {...rebassProps}
    >
      <DeletedOverlay
        onUndoClick={onDiscard}
        discardedMessage={<>We won&rsquo;t show you this job anymore</>}
        showDiscardedOverlay={showDiscardedOverlay}
        isDiscarded={isDiscarded}
      />
      <Flex
        width={1}
        sx={{
          transition: 'all ease .3s',
          filter: showDiscardedOverlay && isDiscarded ? 'blur(2px) grayscale(100%)' : '',
          opacity: showDiscardedOverlay && isDiscarded ? 0.3 : 1,
        }}
        itemScope
        itemType="https://schema.org/JobPosting"
      >
        {!simplifiedVersion && (
          <Box className="company-thumbnail" sx={{ height: ['40px', '72px'] }} width={['40px', '72px']} mr={2}>
            <NextLink
              shallow
              passHref
              href={{
                pathname: `/companies/${organization.slug}`,
                hash: '#content',
                query,
              }}
              legacyBehavior
            >
              <Button
                as="a"
                data-testid="company-logo-link"
                className="theme_only"
                m={0}
                variant="buttons.getroLink"
                sx={{ height: '100%' }}
              >
                <ProfilePicture
                  name={organization.name}
                  imageUrl={organization.logoUrl}
                  variant="square"
                  alignItems="flex-start"
                  imageWidth={72}
                  imageHeight={72}
                />
              </Button>
            </NextLink>
          </Box>
        )}
        <Flex width={[1 / 2]} className="job-info" px={2} flexDirection="column" flex="1 1 auto">
          <meta itemProp="description" content={`${title} at ${organization.name}`} />
          <Text mb={1} as="h4" fontSize={[2, 3]} width={[1]}>
            <Link
              display="inline-block"
              className="theme_only"
              target="_blank"
              href={jobUrl}
              variant="buttons.getroLink"
              onClick={onJobLinkClickHandler}
              data-testid="job-title-link"
              sx={{
                maxWidth: '100%',
                whiteSpace: 'break-spaces',
              }}
            >
              <Flex flexDirection={['column-reverse', 'row']} alignItems="baseline">
                <Text
                  itemProp="title"
                  fontSize={[2, 3]}
                  color="text.dark"
                  fontWeight="medium"
                  sx={{
                    whiteSpace: 'break-spaces;',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {title}
                </Text>
                {isFeatured && (
                  <Text ml={[0, 1]} fontSize={[0]} color="customPrimary" fontWeight="medium">
                    Featured
                  </Text>
                )}
              </Flex>
            </Link>
          </Text>
          <Box>
            <div itemProp="hiringOrganization" itemScope itemType="https://schema.org/Organization">
              <meta itemProp="logo" content={organization.logoUrl} />
              <meta itemProp="name" content={organization.name} />
              {!simplifiedVersion && (
                <NextLink
                  shallow
                  passHref
                  href={{
                    pathname: `/companies/${organization.slug}`,
                    hash: '#content',
                    query,
                  }}
                  legacyBehavior
                >
                  <Button
                    as="a"
                    data-testid="link"
                    className="theme_only"
                    fontSize={[1, 2]}
                    mb={2}
                    display="inline-block"
                    variant="buttons.getroLink"
                    sx={{
                      whiteSpace: 'break-spaces',
                      textAlign: 'left',
                    }}
                  >
                    {organization.name}
                  </Button>
                </NextLink>
              )}
            </div>
            <Flex flexWrap={['wrap']} sx={{ gap: '16px' }}>
              {locations && locations.length > 0 && (
                <div itemProp="jobLocation" itemScope itemType="https://schema.org/Place">
                  <meta itemProp="address" content={locations.join('; ')} />
                  <div>
                    <Flex alignItems="flex-start" color="neutral.400">
                      <Box as={MapPin} height="16px" width="16px" aria-hidden="true" />
                      <Text fontSize={1} color="text.main" ml="4px">
                        {moveRemoteToEnd(locations).map((loc, index) => (
                          <React.Fragment key={loc}>
                            <Box as="span" sx={{ textTransform: 'capitalize' }}>
                              {loc}
                            </Box>
                            {index !== locations.length - 1 && <>; </>}
                          </React.Fragment>
                        ))}
                      </Text>
                    </Flex>
                  </div>
                </div>
              )}
              {compensation?.amountMinCents &&
                compensation?.amountMaxCents &&
                compensation?.currency &&
                compensation?.period && (
                  <Flex alignItems="center" sx={{ gap: '4px' }} color="neutral.400">
                    <Box as={Banknote} height="16px" width="16px" aria-hidden="true" />
                    <DisplayCompensationPeriod compensation={compensation} fontSize={1} color="text.main" />
                  </Flex>
                )}
              <Flex className="added" alignItems="center" color="neutral.400" sx={{ gap: '4px' }}>
                <Box as={Calendar} height="16px" width="16px" aria-hidden="true" />
                <Text fontSize={1} color="text.main">
                  {moment.unix(createdAt).fromNow()}
                  <meta itemProp="datePosted" content={`${moment.unix(createdAt).toISOString().split('T')[0]}`} />
                </Text>
              </Flex>
            </Flex>
            {hasTags && (
              <Flex sx={{ mt: '8px', rowGap: '4px', columnGap: '4px', flexWrap: 'wrap', '& > div': { mr: '0' } }}>
                {hasIndustryTagsVisible && !simplifiedVersion && (
                  <>
                    {organization.industryTags
                      .filter((tag) => tag.toLowerCase() !== 'information technology')
                      .map((tag) => (
                        <Tag key={tag} variant="transparent">
                          {tag}
                        </Tag>
                      ))}
                  </>
                )}
                {hasCompanySizeTagsVisible && !simplifiedVersion && (
                  <Tag key={organization.headCount} variant="transparent">
                    {`${mapHeadCountToInterval(organization.headCount)} employees`}
                  </Tag>
                )}
                {hasStageTagsVisible && !simplifiedVersion && companyStageFormatter(organization.stage) !== 'Other' && (
                  <Tag key={organization.stage} variant="transparent">
                    {companyStageFormatter(organization.stage)}
                  </Tag>
                )}
                {visibleTopics.map((tag) => (
                  <Tag key={tag} variant="transparent">
                    {tag}
                  </Tag>
                ))}
                {seniority && !simplifiedVersion && (
                  <Tag key="seniority" variant="transparent">
                    {JOB_SENIORITY_VALUES[seniority]}
                  </Tag>
                )}
              </Flex>
            )}
          </Box>
        </Flex>
        <Box alignSelf="center" data-testid="controls" with={['22%']} display="flex">
          {canDiscard && (
            <Button
              p={0}
              pr={1}
              data-testid="discard"
              display={['flex']}
              className="hover-active"
              onClick={onDiscard}
              title={isDiscarded ? 'Undo Discard' : 'Discard'}
              variant="cardControl"
              mx={0}
              sx={{
                border: 'none',
                opacity: [1, isDiscarded ? 1 : 0],
                borderColor: 'neutral.400',
                p: 1,
              }}
            >
              <Box
                alignItems="center"
                justifyContent="center"
                variant="cardControl"
                display="flex"
                sx={{
                  bg: isDiscarded ? 'neutral.400' : 'transparent',
                  color: isDiscarded ? 'white' : 'neutral.400',
                  borderRadius: 'circle',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  height: '24px',
                  width: '24px',
                }}
              >
                <Box
                  variant="icon"
                  className="fa fa-close-o"
                  height="14px"
                  width="14px"
                  strokeWidth="2"
                  aria-hidden="true"
                  as={X}
                />
                <Text variant="srOnly">{isDiscarded ? 'Undo Discard' : 'Discard'}</Text>
              </Box>
            </Button>
          )}
          {canFavorite && (
            <Box
              variant="buttons.cardControl"
              className="hover-active"
              mx={0}
              sx={{
                border: 'none',
                opacity: [1, isFavorite ? 1 : 0],
                borderColor: 'neutral.400',
                p: 1,
              }}
            >
              <ButtonToggle
                p={0}
                pl={1}
                data-testid="favorite"
                data-is-checked={JSON.stringify(isFavorite)}
                onClick={onFavorite}
                checked={!!isFavorite}
                aria-details="Click to add or remove a jobs from your favorites"
                CheckboxChecked={() => (
                  <Flex>
                    <Box
                      variant="buttons.primary"
                      alignItems="center"
                      justifyContent="center"
                      display="flex"
                      p={1}
                      sx={{
                        borderRadius: 'circle',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        height: '24px',
                        width: '24px',
                      }}
                    >
                      <Box
                        variant="icon"
                        fill="currentColor"
                        className="fa fa-heart-o"
                        height="14px"
                        width="14px"
                        strokeWidth="1.5"
                        aria-hidden="true"
                        as={Heart}
                      />
                      <Text variant="srOnly">Favorite</Text>
                    </Box>
                  </Flex>
                )}
                unCheckedState={() => (
                  <Flex>
                    <Box
                      variant="buttons.accent"
                      alignItems="center"
                      justifyContent="center"
                      display="flex"
                      p={1}
                      sx={{
                        borderRadius: 'circle',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        height: '24px',
                        width: '24px',
                      }}
                    >
                      <Box
                        variant="icon"
                        fill="white"
                        className="fa fa-heart-o"
                        height="14px"
                        width="14px"
                        strokeWidth="1.5"
                        aria-hidden="true"
                        as={Heart}
                      />
                      <Text variant="srOnly">Undo Favorite</Text>
                    </Box>
                  </Flex>
                )}
              />
            </Box>
          )}
        </Box>
        <Flex
          height={['0', '0', 'unset']}
          width={['0', '0', 'unset']}
          sx={{ overflow: 'hidden' }}
          alignSelf="center"
          px={[0, 0, 2]}
          alignItems="center"
        >
          <Link
            onClick={onJobLinkClickHandler}
            className="theme_only"
            data-testid="read-more"
            href={jobUrl}
            data-jobsource={source}
            target="_blank"
            width={[1]}
            variant="buttons.jobCardLink"
            fontSize={1}
            sx={{
              position: 'static',
              bg: 'white',
              ':hover': {
                bg: 'white',
              },
              ':active': { transform: 'none' },
              ...(hasAdvancedDesign ? { color: primary } : {}),
            }}
          >
            Read more
            <Text variant="srOnly">
              about {title} at {organization.name}
            </Text>
            <Box
              variant="icon"
              className="fa fa-chevron-right"
              height="16px"
              width="16px"
              strokeWidth="1.5"
              aria-hidden="true"
              as={ChevronRight}
              mt={[1]}
              ml={[1]}
            />
          </Link>
        </Flex>
      </Flex>
    </Card>
  );
};

JobListItem.propTypes = {
  source: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  seniority: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.string).isRequired,
  organization: PropTypes.shape({
    id: PropTypes.number.isRequired,
    logoUrl: PropTypes.string,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    industryTags: PropTypes.arrayOf(PropTypes.string),
    topics: PropTypes.arrayOf(PropTypes.string),
    headCount: PropTypes.number,
    stage: PropTypes.string,
  }).isRequired,
  createdAt: PropTypes.number.isRequired,
  rebassProps: PropTypes.object,
  isLast: PropTypes.bool,
  showDiscardedOverlay: PropTypes.bool,
  canFavorite: PropTypes.bool,
  canDiscard: PropTypes.bool,
  isFavorite: PropTypes.bool,
  isDiscarded: PropTypes.bool,
  isFeatured: PropTypes.bool,
  url: PropTypes.string,
  onFavorite: PropTypes.func,
  onDiscard: PropTypes.func,
  onJobLinkClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  simplifiedVersion: PropTypes.bool,
  hasDescription: PropTypes.bool.isRequired,
  network: NetworkSchema.isRequired,
  compensation: PropTypes.object,
};

JobListItem.defaultProps = {
  rebassProps: {},
  isLast: false,
  url: '',
  showDiscardedOverlay: false,
  canFavorite: true,
  canDiscard: true,
  isFavorite: false,
  isDiscarded: false,
  isFeatured: false,
  onDiscard: () => {},
  onFavorite: () => {},
  description: null,
  seniority: null,
  simplifiedVersion: false,
  compensation: {},
};
